import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {dotenv, routes} from "../../config/config";
import {messengers} from "../../schemas/frontend/effects";
import {
    Button, Carousel,
    Col, Form, Input, InputNumber,
    Layout, Popconfirm, Radio,
    Row, Slider,
    Table, Tag,
    Tooltip,
    Typography
} from "antd";

import {
    getUtcDateTime,
    momentFromUnix,
    deepGet,
    getBotUrl,
    numberForHuman,
    getUserFullName,
    getFileSizeString,
    inArray, redirect, addUniqueValueToArray, removeValueFromArray, toNum, createMarkup, roundPrice,
} from "../../library/functions";

import {AreaChart, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer, Area} from 'recharts';
import {useTranslation} from "react-i18next";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Banner} from "../../components/Banner/Banner";
import {CustomHeader} from "../../components/Layout/CustomHeader";
import {ContentCard} from "../../components/Card/Content/ContentCard";
import {ProgressBar} from "../../components/ProgressBar/ProgressBar";
import {NavigateBtn, NavigateLink} from "../../components/Navigate/Navigate";
import Fa from "../../components/Icon/Fa";
import {
    CalendarOutlined,
    CheckOutlined,
    CommentOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    PlusOutlined,
    FolderOpenOutlined,
    ShoppingOutlined,
} from "@ant-design/icons";
import {reachGoal} from "../../library/metrics";
import {notice} from "../../library/notice";
import {LabelSwitch} from "../../components/Form/Field/LabelSwitch";
import InputModal from "../../components/Modal/InputModal";
import {SimpleModal} from "../../components/Modal/SimpleModal";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {NoForm} from "../../components/Form/NoForm";
import {FormItemLabel} from "../../components/Form/FormItemLabel";
import {VideoSimple} from "../../components/Media/VideoSimple";

const {Content} = Layout;
const {Title, Text} = Typography;

const Overview = () => {
    const {t} = useTranslation()
    const section = 'tariff'

    // data from URL params
    const params = useParams()
    const project_id = Number(params.project_id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, integration, userField, order, discount, sect} = useSelector(store => store)
    const carouselRef = useRef(null);
    const videoRef = useRef();

    const project_item = project.item
    const tariffs = sect.tariff;

    const chartData = project_item?.stat?.history.map(row => ({
        date: String(row.date_stamp).substring(6, 8) + '.' + String(row.date_stamp).substring(4, 6),
        amount: row.amount,
    })) || [];

    const projectTariffType = project_item.tariff === 'bonus' ? 'start' : project_item.tariff;
    const stat = deepGet(project_item, 'stat', {}) || {};

    const adminContacts = deepGet(admin, 'user.contacts', []) || [];
    const connected = adminContacts.map(contact => contact.type);
    const isContactsConnected = inArray('tg_id', connected) && inArray('vk_id', connected);
    // console.log('admin.user', admin.user.contacts, connected)

    const balance = deepGet(userField, 'balance.value', 0)
    const discountItem = discount.list.length ? discount.list[0] : {'id': 0, 'amount': 10, 'name': ''}

    let admin_phones = []
    if (admin.user && admin.user.contacts) admin_phones = admin.user.contacts.filter(e => e.type === 'phone')
    const admin_phone = admin_phones.length ? admin_phones[0].value : ''

    // const [checkedSections, setCheckedSections] = useState(['graph', 'templates', 'gamification', 'sales'])
    const [aiForm] = Form.useForm();
    const [checkedSections, setCheckedSections] = useState(['graph'])
    const [pageParams, setPageParams] = useState({
        messages: {
            limit: 0,
            batch: 0,
        },
        amount: {
            messages: 1000,
            pages: 10,
            space: 10,
            months: 1,
            order: 0,
            project: 0,
            discount: 0,
        },
        ai: {
            amount: 0,
            from_project: false,
        },
        order: {
            phone: '',
            auto: false,
            subscribe: false,
            to_project: true,
        },
        modal: {
            tariff: false,
            order: false,
            promo: false,
            project: false,
            ai: false,
        },
        view: {
            tight: false,
        },
        tariff: {
            selected: null,
            sections: ['graph']
        },
        promo: {
            show: false,
            bonus: 10,
            code: '',
            name: '',
            value: 0,
        },
    })

    const setCurrentTariffData = () => {
        setPageParams({
            ...pageParams,
            amount: {
                ...pageParams.amount,
                messages: project_item.daily_count,
                pages: project_item.pages_limit,
                space: project_item.space_limit,
            }
        })

        setCheckedSections(project_item.sections || [])
    }

    useEffect(() => {
        const anchor = window.location.hash || '#banner';
        if (anchor) {
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [window.location.hash]);

    useEffect(() => {
        if (admin.authorized && admin.token) {
            dispatch({type: 'getProjectItem', admin, id: project_id, data: {extended: true}});

            if (!integration.list.length) {
                dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_id, platforms: messengers}})
            }

            if (!isContactsConnected) {
                dispatch({type: 'getAdminItem', admin});
            }

            dispatch({type: 'getSectData', admin, section: 'tariff', filters: {is_on: true, page_size: 0}});

            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: ['balance'],
                }
            })

            dispatch({
                type: 'getDiscountList',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    product_id: dotenv.main_product,
                    partner_user_ids: [admin.user.id],
                    page_size: 1
                }
            })
        }
    }, [admin.authorized, project_id, window.location.pathname])

    useEffect(() => {
        // console.log('projectTariffType', projectTariffType)
        // setState('tariff.type', projectTariffType)
        setState('tariff.selected', projectTariffType)

        const activeIndex = tariffs.findIndex(tariff => projectTariffType === tariff.name);
        if (carouselRef.current && activeIndex !== -1) {
            carouselRef.current.goTo(activeIndex, true); // Set exact slide
        }

    }, [projectTariffType, tariffs])

    useEffect(() => {
        if (projectTariffType === 'custom') setCurrentTariffData()
    }, [project_item.daily_count])

    useEffect(() => {
        if (order.url) redirect(order.url)
        // if (order.url) console.log('order.url', order.url)
        // else if (order.url && !order.loading.balance) dispatch(setOrderUrl(''))
        //eslint-disable-next-line
    }, [order.url])

    useEffect(() => {
        if (!pageParams.order.phone) setState('order.phone', admin_phone)
    }, [admin_phone])

    useEffect(() => {
        if (discountItem.id) {
            // console.log('discountItem.id', discountItem.id)
            // setState('promo.name', discountItem.name)
            // setState('promo.bonus', discountItem.amount)

            setPageParams({
                ...pageParams,
                promo: {
                    ...pageParams.promo,
                    name: discountItem.is_on ? discountItem.name : '',
                    bonus: discountItem.amount,
                }
            })
        }
        //eslint-disable-next-line
    }, [discountItem.id])

    // local locale func
    const l = (c) => t('project.' + section + '.' + c)

    const navigateToEdit = (e, anchor = '') => {
        navigate(`${routes.project_list}/${project_item.id}/edit${anchor}`);
    }

    // const navigateToTariff = () => {
    //     navigate(`${routes.project_list}/${project_item.id}/tariff`);
    // }

    const setState = (key, value) => {
        // console.log('setState', key, value)

        const keys = key.split('.');
        const firstKey = keys.shift();
        const lastKey = keys.pop();

        let newSection = {...pageParams[firstKey]};
        newSection[lastKey] = value;
        setPageParams({...pageParams, [firstKey]: newSection});
    }

    const onTariffFormSubmit = () => {
        // const tariffPrice = getTariffPrice();
        // console.log('onTariffFormSubmit', tariffPrice)

        const tariffData = {
            project_id: project_item.id,
            sections: checkedSections,
            tariff: pageParams.tariff.selected,
            daily_count: pageParams.amount.messages,
            month_count: pageParams.amount.months,
            pages_limit: pageParams.amount.pages,
            space_limit: pageParams.amount.space,
        }

        // catch metrics goal
        reachGoal('tariff', tariffData)

        dispatch({
            type: 'purchaseAppTariff',
            admin,
            data: tariffData
        })

        // setPageParams({
        //     ...pageParams,
        //     messages: {...pageParams.messages, limit: 0, batch: 0},
        //     amount: {...pageParams.amount, months: 1, order: 0, project: 0, discount: 0},
        // })
        // setCheckedSections([])
    }

    const onOrderFormSubmit = () => {
        // console.log('onOrderFormSubmit', value)

        // catch metrics goal
        reachGoal('order', {order_price: pageParams.amount.order})

        // const orderValues = orderForm.getFieldsValue();
        // console.log('orderValues', orderValues)

        const data = {
            // title: l('account.amount.product_title'),
            price: pageParams.amount.order,
            promo: pageParams.promo.code,
            user_phone: pageParams.order.phone,
            subscribe: pageParams.order.subscribe,
            renew_tariff: pageParams.order.auto,
            to_project: pageParams.order.to_project,
            related_project_id: project_item.id,
        }
        // console.log('onOrderFormSubmit data', data)
        dispatch({type: 'createAppOrder', admin, data})
    }

    // const onOk = () => {
    //     orderForm.submit()
    // }

    const setProjectTariff = () => {
        dispatch({
            type: 'setAppTariff',
            admin,
            data: {
                project_id: project_item.id,
                tariff: pageParams.tariff.selected,
            }
        })
    }

    const onAiOk = () => {
        aiForm.submit()
    }

    const onAiFormSubmit = (values) => {
        // console.log('onAiFormSubmit values', values)
        redirect(`https://ap.click/nVEZf/408053834?sum=${values.amount}&id=${project_item.id}`, '_blank')
        // dispatch({
        //     type: 'increaseProjectAiBalance',
        //     admin,
        //     data: {
        //         project_id: project_item.id,
        //         amount: values.amount,
        //         from_project: !!values.from_project
        //     }
        // })
    }

    const onProjectFormSubmit = (value) => {
        // console.log('onProjectFormSubmit', pageParams.amount.project, value)

        // catch metrics goal
        reachGoal('projectBalance', {order_price: value})

        dispatch({
            type: 'increaseProjectBalance',
            admin,
            data: {
                project_id: project_item.id,
                amount: value,
            }
        })
    }

    const onPromoSave = () => {
        // console.log('promoSave', pageParams.promo.name, pageParams.promo.bonus)
        if (!admin.user.id) {
            notice.error('Admin not authorized')
            return
        }

        const discountData = {
            project_id: dotenv.main_project,
            product_id: dotenv.main_product,
            title: l('account.discount.item.title') + ': ' + pageParams.promo.name,
            // name: pageParams.promo.name,
            amount: pageParams.promo.bonus,
            partner_user_id: admin.user.id,
            mode: 'percent',
            params: {
                bonus: {
                    partner: {
                        currency: 'coins',
                        mode: 'percent',
                        amount: Math.round((20 - pageParams.promo.bonus) * 10, 2),
                        limit: 0,
                    }
                }
            }
        }

        if (discountItem.id) {
            if (pageParams.promo.name) {
                discountData['is_on'] = true
                discountData['name'] = pageParams.promo.name
            } else discountData['is_on'] = false

            dispatch({
                type: 'updateDiscount', admin, data: {
                    id: discountItem.id,
                    ...discountData
                }
            })
        } else {
            if (!pageParams.promo.name) {
                notice.info(l('account.promo.error.name_is_empty'))
                return
            }
            discountData['name'] = pageParams.promo.name
            dispatch({type: 'createDiscount', admin, data: discountData})
        }
    }

    const onSectionChecked = (value, event) => {
        // console.log(event?.target?.name)
        const section_name = 'graph'; // event.target.name;
        let checked_sections = [...checkedSections];
        // console.log('event.target.checked', event.target.checked)
        if (value) checked_sections = addUniqueValueToArray(section_name, checked_sections)
        else checked_sections = removeValueFromArray(section_name, checked_sections)
        // console.log('checked_sections', checked_sections)
        setCheckedSections(checked_sections)
    }

    const proj_balance = parseFloat(toNum(project_item.balance))
    const ai_balance = parseFloat(toNum(project_item.ai_balance))

    const getTariffPrice = () => {
        let price = 0;
        const tariff = tariffs.find(e => e.name === pageParams.tariff.selected);
        // console.log('tariff', tariff)

        if (pageParams.tariff.selected === 'custom') {
            price = pageParams.amount.messages * dotenv.daily_price
            for (const checkedSection of checkedSections) {
                const sect_price = deepGet(tariff, ['spec', 'sections', checkedSection])
                if (sect_price) price += sect_price
            }

            price += pageParams.amount.pages * dotenv.page_price;
            price += pageParams.amount.space * dotenv.space_price;

            // if (!inArray(projectTariffType, ['free', 'custom']) && pageParams.amount.months > 1) {
            //     if (pageParams.amount.months > 10) price *= 0.8
            //     else if (pageParams.amount.months > 5) price *= 0.9
            // }

            price *= pageParams.amount.months
        } else {
            if (tariff && tariff.spec.price) price = tariff.spec.price[String(pageParams.amount.months)]
        }

        let amount = price - proj_balance
        if (amount < 0) amount = 900

        // orderForm.setFieldValue('amount', Math.round(amount + 100));
        // setState('amount.order', Math.round(amount + 100))
        return price
    }

    const tariffPrice = getTariffPrice();
    const isEnough = tariffPrice <= proj_balance;
    // const orderPrice = tariffPrice - proj_balance - balance - coins_price;

    const showOrderModal = () => {
        // console.info('pageParams.amount', pageParams.amount)
        // const currentValues = orderForm.getFieldsValue();
        //
        // const newValues = {
        //     amount: currentValues.amount > 0 ? currentValues.amount : Math.round(tariffPrice - proj_balance + 100),
        //     phone: currentValues.phone ?? admin_phone,
        // }
        //
        // orderForm.setFieldsValue(newValues);

        let newAmount = Math.round(tariffPrice - proj_balance + 100)
        if (newAmount < 0) newAmount = 1000

        setPageParams({
            ...pageParams,
            amount: {...pageParams.amount, order: newAmount},
            // order: {...pageParams.order, phone: pageParams.order.phone ?? admin_phone},
            modal: {...pageParams.modal, order: true},
        })
    }

    // const onOrderFormChange = (changedValues) => {
    //     console.log('changedValues', changedValues)
    //     if (changedValues.amount !== undefined) setState('amount.order', toNum(changedValues.amount));
    // }

    const onAiFormChange = (changedValues) => {
        if (changedValues.amount !== undefined) setState('ai.amount', toNum(changedValues.amount));
    }

    // console.log('tariffs', tariffs)
    const isCustom = pageParams.tariff.selected === 'custom';  // custom

    const selectTariff = (tariff) => {
        if (!tariff || !tariff.spec) {
            console.info('Select Tariff: record not found')
            return
        }

        let tariffAmount = {
            ...pageParams.amount,
            messages: tariff.spec.messages || 1000000,
            pages: tariff.spec.pages || 10000,
            space: tariff.spec.space,
        }

        if (tariff === 'custom' && projectTariffType === 'custom') {
            setCheckedSections(project_item.sections || [])

            tariffAmount = {
                ...pageParams.amount,
                messages: project_item.daily_count,
                pages: project_item.pages_limit,
                space: project_item.space_limit,
            }
        } else {
            setCheckedSections(['graph'])
        }

        setPageParams({
            ...pageParams,
            tariff: {
                ...pageParams.tariff,
                selected: tariff.name,
            },
            amount: tariffAmount
        })
    }

    const carouselOnChange = (index) => {
        setPageParams({
            ...pageParams,
            tariff: {
                ...pageParams.tariff,
                selected: tariffs[index].name,
            }
        })
    }

    // console.log('projectTariffType 2', projectTariffType)

    const renderApplyButton = () => {
        if (projectTariffType === 'free' && pageParams.tariff.selected === 'free') return null;

        if (projectTariffType !== pageParams.tariff.selected) {
            return <div className="inline">
                <Popconfirm
                    title={l('calc.pay.confirm')}
                    onConfirm={inArray(projectTariffType, ['bonus']) ? onTariffFormSubmit : setProjectTariff}
                    // onConfirm={setProjectTariff}
                    icon={null}
                    placement="topLeft"
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    cancelButtonProps={{type: 'text'}}
                    getPopupContainer={() => document.getElementById('app-page-wrapper')}
                >
                    <Button
                        // style={{marginTop: 6}}
                        className="height-auto margin-right-sm margin-bottom-ps visible-xxl"
                        type="primary"
                        size="large"
                        loading={order.loading.messages}
                        // disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.selected}
                        icon={<CheckOutlined/>}
                    >{l('calc.pay.change')}</Button>

                    <Button
                        // style={{marginTop: 6}}
                        className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                        type="primary"
                        size="default"
                        loading={order.loading.messages}
                        disabled={!isEnough && tariffPrice && inArray(projectTariffType, ['bonus'])}
                        // disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                        icon={<CheckOutlined/>}
                    >{l('calc.pay.change')}</Button>
                </Popconfirm>

                <div className="color-secondary">
                    {(projectTariffType === 'free') ? l('calc.pay.change_desc_2') : (inArray(pageParams.tariff.selected, ['free', 'custom']) ? l('calc.pay.change_desc_3') : l('calc.pay.change_desc_1'))}
                </div>
            </div>
        }

        if (!isEnough && tariffPrice) {
            return <div className="inline">
                <Button
                    type="primary"
                    size="large"
                    className="height-auto margin-right-sm margin-bottom-ps visible-xxl"
                    loading={order.loading.balance}
                    icon={<ShoppingOutlined/>}
                    onClick={showOrderModal}
                >{l('account.balance.modal.title')}</Button>

                <Button
                    type="primary"
                    size="default"
                    className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                    loading={order.loading.balance}
                    icon={<ShoppingOutlined/>}
                    onClick={showOrderModal}
                >{l('account.balance.modal.title')}</Button>
            </div>
        }

        return <Tooltip
            title={isEnough && tariffPrice ? '' : l('calc.pay.forbidden')}
            placement="topLeft"
        >

            <div className="inline">
                <Popconfirm
                    title={l('calc.pay.confirm')}
                    onConfirm={onTariffFormSubmit}
                    icon={null}
                    placement="topLeft"
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    cancelButtonProps={{type: 'text'}}
                    getPopupContainer={() => document.getElementById('app-page-wrapper')}
                >
                    <Button
                        // style={{marginTop: 6}}
                        className="height-auto margin-right-sm visible-xxl"
                        type="primary"
                        size="large"
                        loading={order.loading.messages}
                        disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.selected}
                        icon={<CheckOutlined/>}
                    >{l('calc.pay.apply')}</Button>

                    <Button
                        // style={{marginTop: 6}}
                        className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                        type="primary"
                        size="default"
                        loading={order.loading.messages}
                        disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.selected}
                        icon={<CheckOutlined/>}
                    >{l('calc.pay.apply')}</Button>
                </Popconfirm>
            </div>
        </Tooltip>

    }

    const expire_date = momentFromUnix(project_item.tariff_expire_at, "D MMMM");
    const expire_date_parts = [
        momentFromUnix(project_item.tariff_expire_at, "D MMMM YYYY"),
        t('common.at'),
        momentFromUnix(project_item.tariff_expire_at, "H:mm")
    ]

    const now = getUtcDateTime();
    const date_warning = now > project_item.tariff_expire_at - 7 * 60 * 60 * 24;
    // const messages_warning = project_item.permanent_messages < (now > project_item.tariff_expire_at ? 100 : 0)

    const memory_used = deepGet(stat, 'memory.used', 0);
    const memory_limit = deepGet(project_item, 'memory.limit', 1024 * 1024 * 1024 * 10);
    const memory_warning = memory_used > (memory_limit - 1024 * 1024 * 100);

    let isExpired = false;
    let dateWarningClassname = date_warning ? 'color-danger' : '';
    if (date_warning && project_item.permanent_messages > 0 && project_item.tariff_expire_at + 7 * 60 * 60 * 24 < now) {
        dateWarningClassname = 'color-gray-6';
        isExpired = true;
    }

    let isToday = false;
    let update_date = l('stat.today');
    if (project_item.daily_updated_at) {
        // TODO. use something like date.setUTCFullYear(2022,8,25) for getting correct date
        const updatedAtDate = new Date(project_item.daily_updated_at * 1000);
        const todayMidnight = new Date();
        todayMidnight.setUTCHours(3, 0, 0, 0);

        isToday = updatedAtDate > todayMidnight;
        update_date = momentFromUnix(project_item.daily_updated_at, "D MMMM")
        // console.log('compare', todayMidnight, updatedAtDate)
    }

    // start tasks
    const tasks = deepGet(project_item, 'params.start', {}) || {};
    const integrationsUrl = `${routes.project_list}/${project_id}${routes.local.integration_list}`;
    let messagesUrl = integrationsUrl;

    // console.log('integration.list', integration.list)
    if (integration.list.length) {
        const integrationItem = integration.list[0];
        const botUrl = getBotUrl(integrationItem, true);
        if (botUrl) messagesUrl = botUrl;
    }

    // receive free items from stat info
    let freeUsersResult = [];

    const freePagesResult = deepGet(stat, 'free.pages', []) || [];
    const freeContacts = deepGet(stat, 'free.contacts', []) || [];

    for (const freeContact of freeContacts) freeUsersResult.push({
        id: freeContact.id,
        name: getUserFullName(freeContact),
    });

    return (
        <AppWrapper className="bg-gray-light">
            <Layout className="site-layout site-layout-transparent">
                <Banner id="banner" image="universe"/>
                <CustomHeader
                    key="tariff-header"
                    className={'padding-none-horizontal'}
                    title={project_item.title}
                    actions={[
                        <NavigateBtn
                            key="edit"
                            type="text"
                            icon={<SettingOutlined/>}
                            className="default"
                            link={`${routes.project_list}/${project_item.id}/edit`}
                        >
                            {t('common.button.settings')}
                        </NavigateBtn>
                    ]}
                />
                <Content className="page-container container-with-cards padding-none-vertical">
                    {(project_item.id && !tasks.done) && <ContentCard flat_xs={true} className="full-height">
                        <Row gutter={30} align="stretch">
                            {/* left column start */}
                            <Col xs={24} xl={12}>
                                {/* Start Tasks */}
                                <div className="padding-bottom-md">
                                    <Title level={2}>
                                        {l('start.title')}
                                    </Title>

                                    <Table
                                        // TODO: replace help links to config
                                        showHeader={false}
                                        pagination={false}
                                        className="font-size-sm border-last-none"
                                        bordered={false}
                                        columns={[
                                            {
                                                title: 'Text',
                                                dataIndex: 'text',
                                                key: 'text',
                                                className: 'padding-left-none padding-vertical-xs',
                                                render: (val, record) => <div className="inline">
                                                    {val} <Tooltip title={l('start.help')}>
                                                    <Button
                                                        type="text"
                                                        className="font-size-sm inverted padding-top-xxs"
                                                        icon={<QuestionCircleOutlined/>}
                                                        href={record.help}
                                                        target="_blank"
                                                        size={'small'}
                                                    />
                                                </Tooltip>
                                                </div>
                                            },
                                            {
                                                title: 'Buttons',
                                                dataIndex: 'actions',
                                                key: 'actions',
                                                className: 'padding-none-horizontal padding-vertical-xs border-none vertical-align-top',
                                                align: 'right',
                                                width: 150,
                                                render: (col, record) => <div className="table-row-buttons">
                                                    {record.status ? <Button
                                                        type="text"
                                                        icon={<CheckOutlined/>}
                                                        className="inverted padding-none-horizontal"
                                                    >
                                                        {l('start.done')}
                                                    </Button> : <NavigateBtn
                                                        type="primary"
                                                        link={record.url}
                                                        children={l('start.execute')}
                                                        className="btn-bordered"
                                                        target={record.external ? '_blank' : null}
                                                    />}
                                                </div>,
                                            },
                                            // Add more columns as needed
                                        ]}
                                        dataSource={[
                                            {
                                                text: l('start.integration'),
                                                status: !!tasks.integration,
                                                url: integrationsUrl,
                                                help: `${dotenv.docs_url}/integration`
                                            },
                                            {
                                                text: l('start.alert'),
                                                status: !!tasks['alert'],
                                                // url: `${routes.project_list}/${project_id}/edit#alerts`,
                                                url: `https://t.me/${dotenv.alerts_bot_name}?start=alerts-1--pr-${project_id}`,
                                                help: `${dotenv.docs_url}/alerts`,
                                                external: true,
                                            },
                                            {
                                                text: l('start.template'),
                                                status: !!tasks.template,
                                                url: `${routes.project_list}/${project_id}${routes.local.template_store}/${dotenv.hello_template}`,
                                                help: `${dotenv.docs_url}/templates/store`
                                            },
                                            {
                                                text: l('start.message'),
                                                status: !!tasks.message,
                                                url: messagesUrl,
                                                external: !(messagesUrl === integrationsUrl),
                                                help: `${dotenv.docs_url}/graph/examples/keyword-reaction`,
                                            },
                                            {
                                                text: l('start.contact'),
                                                status: !!tasks.contact,
                                                url: `${routes.project_list}/${project_id}${routes.local.user_list}`,
                                                help: `${dotenv.docs_url}/tariff/free-contacts`
                                            },
                                        ]}
                                    />
                                </div>
                            </Col>

                            {/* right column start */}
                            <Col xs={24} xl={12}>
                                {/* Video review */}
                                <div className="margin-bottom-ps">
                                    <Title level={2}>
                                        {l('start.video')}
                                    </Title>
                                </div>

                                <VideoSimple
                                    videoRef={videoRef}
                                    url={dotenv.welcome_video_url}
                                    buttonStyle="dark"
                                    controls={true}
                                />
                            </Col>
                        </Row>
                    </ContentCard>}
                    <Row gutter={30} align="stretch">
                        {/* left column start */}
                        <Col xs={24} xl={12} className="margin-top-pm xs-margin-top-none">
                            <ContentCard flat_xs={true} className="full-height">
                                {/* Contact bonuses. TODO: check on finished + reload */}
                                {admin.user.contacts && isContactsConnected ? null : <div className="padding-bottom-md">
                                    <Title level={2}>
                                        {l('contact.title')}
                                    </Title>

                                    <Table
                                        showHeader={false}
                                        pagination={false}
                                        className="font-size-sm border-last-none"
                                        bordered={false}
                                        columns={[
                                            {
                                                title: 'Text',
                                                dataIndex: 'text',
                                                key: 'text',
                                                className: 'padding-left-none padding-vertical-xs',
                                                render: (val, record) => <div className="inline">
                                                    <Tooltip title={l('contact.tooltip')}>
                                                        <span className="margin-right-xp">{val}</span>
                                                        {record.status ? null :
                                                            <span className="ant-badge ant-badge-not-a-wrapper">
                                                            <sup
                                                                data-show="true"
                                                                style={{backgroundColor: '#52c41a'}}
                                                                className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                                                            >
                                                                +1000 {l('contact.coins')}
                                                            </sup>
                                                         </span>}
                                                    </Tooltip>
                                                </div>
                                            },
                                            {
                                                title: 'Buttons',
                                                dataIndex: 'actions',
                                                key: 'actions',
                                                className: 'padding-none-horizontal padding-vertical-xs border-none vertical-align-top',
                                                align: 'right',
                                                width: 180,
                                                render: (col, record) => <div className="table-row-buttons">
                                                    {record.status ? <Button
                                                        type="text"
                                                        icon={<CheckOutlined/>}
                                                        className="inverted padding-none-horizontal"
                                                    >
                                                        {l('start.done')}
                                                    </Button> : <Button
                                                        type="primary"
                                                        icon={<PlusOutlined/>}
                                                        href={record.url}
                                                        children={l('contact.apply')}
                                                        className={record.bordered ? "btn-bordered" : null}
                                                        target="'_blank"
                                                    />}
                                                </div>,
                                            },
                                            // Add more columns as needed
                                        ]}
                                        dataSource={[
                                            {
                                                text: l('contact.tg'),
                                                url: dotenv.main_bot_url + `?start=a-cnt--uh-${admin.user.key}--pr-${project_id}`,
                                                status: inArray('tg_id', connected),
                                                bordered: false,
                                            },
                                            {
                                                text: l('contact.vk'),
                                                url: dotenv.mini_app_url + `#connect?uh=${admin.user.key}&pr=${project_id}`,
                                                status: inArray('vk_id', connected),
                                                bordered: true
                                            },
                                        ]}
                                    />
                                </div>}

                                {/* Текущий тариф */}
                                <Title level={2}>
                                    {l('about')}
                                </Title>

                                <div className="margin-top-sm">
                                    {l('stat.summary.stat.title')}
                                    <ul className="list-style-secondary">
                                        <li>{l('stat.summary.stat.users')}: {
                                            numberForHuman(deepGet(stat, 'users', 0))
                                        }</li>
                                        <li>{l('stat.summary.stat.contacts')}: {
                                            numberForHuman(deepGet(stat, 'contacts', 0))
                                        }</li>
                                        <li>{l('stat.summary.stat.chats')}: {
                                            deepGet(stat, 'chats', 0)
                                        }</li>
                                        <li>{l('stat.summary.stat.money')}: {
                                            numberForHuman(deepGet(stat, 'money', 0)) + ' ' + t('common.money.abbreviation')
                                        }</li>
                                    </ul>
                                    <br/>
                                    {l('stat.summary.info.title')}
                                    <ul className="list-style-secondary">
                                        <li>{l('stat.summary.info.contacts')}: <span
                                            className="color-secondary">{freeUsersResult.length} / 3</span>
                                            <ul>{freeUsersResult.map((item, index) => {
                                                return <li key={"cnt" + item.id}>
                                                    <NavigateLink
                                                        link={`${routes.project_list}/${project_id}${routes.local.user_list}/profile/${item.id}`}
                                                        className="color-default hover:text-underline"
                                                    >
                                                        {item.name}
                                                    </NavigateLink>
                                                </li>
                                            })}</ul>
                                        </li>
                                        {(projectTariffType === 'free') ? <li>
                                            {l('stat.summary.info.pages')}: <span
                                            className="color-secondary">{freePagesResult.length} / 5</span>
                                            <ul>{freePagesResult.map((item, index) => {
                                                return <li key={"pg" + item.id}>
                                                    <NavigateLink
                                                        link={`${routes.project_list}/${project_id}${routes.local.page_list}/edit/${item.id}?tab=settings`}
                                                        className="color-default hover:text-underline"
                                                    >
                                                        {item.title}
                                                    </NavigateLink>
                                                </li>
                                            })}</ul>
                                        </li> : null}
                                    </ul>
                                </div>

                                {/* Настроить уведомления */}
                                <div className="margin-top-sm">
                                    {/*<Button
                                        type="primary"
                                        onClick={navigateToTariff}
                                        className="margin-right-ps margin-bottom-xp"
                                    >
                                        {l('type.change')}
                                    </Button>*/}

                                    <Button
                                        type="primary"
                                        onClick={navigateToEdit}
                                        className="btn-bordered margin-right-ps margin-bottom-xp"
                                    >
                                        {l('access.control_messages')}
                                    </Button>

                                    <Button
                                        type="text"
                                        className="padding-left-none"
                                        onClick={(e) => navigateToEdit(e, '#alerts')}
                                    >
                                        {l('access.control_alerts')}
                                    </Button>
                                </div>
                            </ContentCard>
                        </Col>

                        {/* right column start */}
                        <Col xs={24} xl={12} className="margin-top-pm xs-margin-top-none" id="tariff-column">
                            <ContentCard flat_xs={true} className="full-height">
                                {/* Остаток сообщений */}
                                <div>
                                    <Title level={2}>
                                        {l('type.title') + ': «' + t('tariff.' + projectTariffType + '.short_title') + '»'}
                                    </Title>

                                    {inArray(projectTariffType, ['free', 'vip']) ? <p>
                                        {projectTariffType === 'free' ? l('stat.free_desc') : l('stat.unlimited')}
                                    </p> : <p>
                                        {l('stat.heading') + ' ' + ((isToday || isExpired) ? l('stat.today') : update_date)}:
                                    </p>}

                                    {(isExpired || inArray(projectTariffType, ['free', 'vip'])) ? null : <ProgressBar
                                        className="margin-top-ps"
                                        lg={true}
                                        max={project_item.daily_count}
                                        current={project_item.daily_messages}
                                        details_as_label={true}
                                        danger_on_low={true}
                                    />}

                                    <Row gutter={24} className="margin-top-pm">
                                        <Col xs={24} sm={8}>
                                            <Tooltip title={l('stat.max_desc')} placement="topLeft">
                                                <Title level={2}>
                                                    {inArray(projectTariffType, ['free', 'vip']) ? <span>
                                                        <CommentOutlined/> <Fa icon="infinity" set="solid"/>
                                                    </span> :
                                                        <span className={isExpired ? 'color-gray-6' : null}>
                                                        <CommentOutlined/> {project_item.daily_count}
                                                    </span>}
                                                </Title>
                                            </Tooltip>
                                            <Text type="secondary">
                                                {l('stat.max')}
                                            </Text>
                                        </Col>
                                        <Col xs={24} sm={8} className="xs-margin-top-pm">
                                            <Tooltip
                                                title={l('stat.files.limit') + ' ' + getFileSizeString(memory_limit, t)}
                                                placement="topLeft"
                                            >
                                                <Title level={2}>
                                                    <span className={memory_warning ? 'color-danger' : ''}>
                                                        <FolderOpenOutlined/>
                                                        {getFileSizeString(memory_used || 0, t)}
                                                    </span>
                                                </Title>
                                                <Text type="secondary">
                                                    {l('stat.files.size')}
                                                </Text>
                                            </Tooltip>
                                        </Col>
                                        <Col xs={24} sm={8} className="xs-margin-top-pm">
                                            <Tooltip
                                                title={project_item.tariff_expire_at ? expire_date_parts.join(' ') : l('stat.expire_at_unset')}
                                                placement="topLeft"
                                            >
                                                <Title level={2}>
                                                    {projectTariffType === 'free' ? <span>
                                                        <CalendarOutlined/> <Fa icon="infinity" set="solid"/>
                                                    </span> :
                                                        <span className={dateWarningClassname}>
                                                        <CalendarOutlined/>
                                                            {project_item.tariff_expire_at ? expire_date :
                                                                <Fa icon="infinity" set="solid"/>}
                                                    </span>}

                                                </Title>
                                            </Tooltip>
                                            <Text
                                                type="secondary">{now > project_item.tariff_expire_at ? l('stat.expired_at') : l('stat.expire_at')}</Text>
                                        </Col>
                                    </Row>
                                </div>

                                {chartData.length > 0 && <div className="margin-top-pm">
                                    <Title level={3}>
                                        {l('chart.title')}
                                    </Title>
                                    <ResponsiveContainer width="100%" height={200} className="margin-top-sm">
                                        <AreaChart
                                            data={chartData}
                                            margin={{top: 10, right: 30, left: 0, bottom: 0}}
                                        >
                                            <defs>
                                                <linearGradient id="colorMessages" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#5D80FF" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#5D80FF" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            <XAxis dataKey="date" interval={1}/>
                                            <YAxis/>
                                            <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5}/>
                                            <ChartTooltip
                                                labelFormatter={(label) => `${l('chart.date')}: ${label}`}
                                                formatter={(value) => [`${value}`, l('chart.messages')]}
                                            />
                                            <Area
                                                type="monotone"
                                                dataKey="amount"
                                                stroke="#5D80FF"
                                                fillOpacity={1}
                                                fill="url(#colorMessages)"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>}

                                {/* Постоянные сообщения */}
                                {project_item.permanent_messages > 0 && <div className="margin-top-md">
                                    <Title level={2}>{l('stat.permanent')}</Title>
                                    <div className="margin-top-ps">
                                        <ul className="list-style-secondary">
                                            <li>{l('stat.permanent_balance')}: {project_item.permanent_messages}</li>
                                        </ul>
                                    </div>
                                </div>}
                            </ContentCard>
                        </Col>
                    </Row>


                    <Title id="tariff" level={2} className="margin-top-md font-medium">{l('title')}</Title>

                    <Row gutter={30} align="stretch">
                        {/* left column start */}
                        <Col xs={24} lg={15} className="margin-top-pm xs-margin-top-none">
                            <div className="visible-lg">
                                <Row gutter={30} align="stretch">
                                    {tariffs && tariffs.map((tariff, index) => {
                                        const isCurrent = projectTariffType === tariff.name;
                                        const isSelected = pageParams.tariff.selected === tariff.name;

                                        return <Col key={index} xs={24} lg={12} className="margin-bottom-ps">
                                            <ContentCard
                                                className="full-height"
                                                onClick={() => selectTariff(tariff)}
                                                interactive={true}
                                                active={isSelected}
                                            >
                                                <Title level={2}
                                                    // className={isCurrent || isSelected ? null : 'color-secondary'}
                                                >
                                                    {t('tariff.' + tariff.name + '.title')}

                                                    {isCurrent ? <Tooltip title={l('cards.active.desc')}>
                                                        <Tag
                                                            className="bg-success margin-left-xp color-white border-none">
                                                            {l('cards.active.label')}
                                                        </Tag>
                                                    </Tooltip> : null}
                                                </Title>
                                                <div dangerouslySetInnerHTML={
                                                    createMarkup(t('tariff.' + tariff.name + '.desc'))
                                                }/>
                                            </ContentCard>
                                        </Col>
                                    })}
                                </Row>
                            </div>

                            <div className="margin-bottom-pm padding-left-xp padding-right-xp hidden-lg">
                                <Carousel ref={carouselRef} afterChange={carouselOnChange} className="white">
                                    {tariffs && tariffs.map((tariff, index) => {
                                        const isCurrent = projectTariffType === tariff.name;
                                        return <ContentCard
                                            key={index}
                                            className="full-height"
                                            flat_xs={true}
                                        >
                                            <Title level={2}>
                                                {isCurrent ? <Tooltip title={l('cards.active')}>
                                                    <CheckOutlined className="margin-right-ps"/>
                                                </Tooltip> : null}
                                                {t('tariff.' + tariff.name + '.title')}
                                            </Title>
                                            <div dangerouslySetInnerHTML={
                                                createMarkup(t('tariff.' + tariff.name + '.desc'))
                                            }/>
                                        </ContentCard>
                                    })}
                                </Carousel>
                            </div>
                        </Col>

                        {/* right column start */}
                        <Col xs={24} lg={9} className="margin-top-pm xs-margin-top-none" id="tariff-column">
                            <ContentCard className="full-height">
                                {/* Калькулятор тарифа */}
                                <Title
                                    level={2}>{l('calc.heading')} {t('tariff.' + pageParams.tariff.selected + '.short_title')}</Title>
                                <div
                                    // className={isDeveloper ? 'hide' : ''}
                                >
                                    <div className="margin-top-ps"/>
                                    {pageParams.amount.messages === 1000000 ? <Fa
                                        wcn="float-right align-right color-gray-6"
                                        set="regular"
                                        icon="infinity"
                                    /> : <InputNumber
                                        inputMode="numeric"
                                        className="float-right align-right always-show-arrows clear-disabled"
                                        bordered={false}
                                        disabled={!isCustom}
                                        min={isCustom ? 1000 : 0}
                                        step={500}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('amount.messages', value)}
                                        value={pageParams.amount.messages}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />}

                                    <Tooltip title={l('calc.messages_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.limit')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    {pageParams.tariff.selected === 'custom' && <Slider
                                        className="primary"
                                        disabled={!isCustom}
                                        min={isCustom ? 1000 : 0}
                                        max={100000}
                                        step={500}
                                        onChange={(value) => setState('amount.messages', value)}
                                        value={pageParams.amount.messages}
                                    />}

                                    <div className="margin-top-ps"/>
                                    {pageParams.amount.pages === 10000 ? <Fa
                                        wcn="float-right align-right color-gray-6"
                                        set="regular"
                                        icon="infinity"
                                    /> : <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="float-right align-right always-show-arrows clear-disabled"
                                        bordered={false}
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        step={10}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('amount.pages', value)}
                                        value={pageParams.amount.pages}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />}
                                    <Tooltip title={l('calc.pages_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.pages')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    {pageParams.tariff.selected === 'custom' && <Slider
                                        className="primary"
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        max={1000}
                                        step={10}
                                        onChange={(value) => setState('amount.pages', value)}
                                        value={pageParams.amount.pages}
                                    />}

                                    <div className="margin-top-ps"/>
                                    <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="float-right align-right always-show-arrows clear-disabled"
                                        bordered={false}
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        step={10}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('amount.space', value)}
                                        value={pageParams.amount.space}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />
                                    <Tooltip title={l('calc.space_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.space')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    {pageParams.tariff.selected === 'custom' && <Slider
                                        className="primary"
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        max={1000}
                                        step={10}
                                        onChange={(value) => setState('amount.space', value)}
                                        value={pageParams.amount.space}
                                    />}
                                </div>

                                {/* Доступ к разделам */}
                                {pageParams.tariff.selected === 'custom' && <div className="margin-top-sm">
                                    {/*<div className="margin-bottom-xp">{l('calc.sections')}:</div>*/}
                                    {pageParams.tariff.sections.map((section_key) => {
                                        if (section_key === 'layout') return null;

                                        const selTar = tariffs.find(t => t.name === pageParams.tariff.selected)
                                        const secPrice = deepGet(selTar, ['spec', 'sections', section_key], 0)

                                        // console.log('secPrice', section_item.key, selTar, deepGet(selTar, ['spec']))
                                        return <div key={section_key} className="checkbox-row">
                                            <span className="float-right">
                                                {secPrice} {t('common.money.symbol')}
                                            </span>
                                            <LabelSwitch
                                                className=""
                                                // size="small"
                                                name={section_key}
                                                onChange={onSectionChecked}
                                                checked={inArray(section_key, checkedSections)}
                                                disabled={!isCustom}
                                                label={l('access.section.' + section_key)}
                                            />
                                        </div>
                                    })}
                                </div>}

                                <div className="margin-top-pl">
                                    {/*<InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="always-show-arrows align-center"
                                        bordered={true}
                                        min={0}
                                        step={1}
                                        placeholder="12"
                                        onChange={(value) => setState('amount.months', value)}
                                        style={{width: 63}}
                                        disabled={!checkedSections.length && !pageParams.amount.messages}
                                        value={pageParams.amount.months}
                                    />*/}

                                    <Text className="margin-right-sm">{l('calc.months.title')}</Text>
                                    <Radio.Group
                                        onChange={(e) => setState('amount.months', e.target.value)}
                                        value={pageParams.amount.months}
                                        disabled={projectTariffType !== pageParams.tariff.selected}
                                        style={{
                                            width: '100%',
                                            marginTop: 10,
                                            marginLeft: 10,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Radio value={1}>{l('calc.months.amount.1')}</Radio>
                                        <Radio value={6}>
                                            {pageParams.tariff.selected === 'custom' ? l('calc.months.amount.6') : l('calc.months.amount.6-discount')}
                                        </Radio>
                                        <Radio value={12}>
                                            {pageParams.tariff.selected === 'custom' ? l('calc.months.amount.12') : l('calc.months.amount.12-discount')}
                                        </Radio>
                                    </Radio.Group>
                                </div>

                                <hr className="margin-top-sm" style={{opacity: 0.5}}/>

                                {/* Стоимость выбранного тарифа | Оплатить тариф с баланса */}
                                <div className="margin-top-pm">
                                    <Row gutter={18} align="stretch">
                                        <Col xs={24} sm={13}>
                                            <div className="ant-typography ant-typography-secondary font-size-md"
                                                 style={{marginTop: 4}}>
                                                {l('calc.pay.price')}:
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={11}>
                                            <Title level={3} className="inline" style={{fontWeight: 400}}>
                                                {roundPrice(tariffPrice)} {t('common.money.abbreviation')}
                                            </Title>
                                        </Col>
                                    </Row>

                                    <div>
                                        {renderApplyButton()}

                                        {/*{proj_balance ?
                                            // <Tooltip title={l('calc.pay.amount_desc')} placement="topLeft">
                                            <div className="inline margin-right-pm">
                                                <Text type="secondary">{l('calc.pay.amount')}: </Text>
                                                <Text
                                                    type="secondary"
                                                    className="nowrap"
                                                >
                                                    {tariffPrice > proj_balance ? roundPrice(tariffPrice - proj_balance) : 0} {t('common.money.abbreviation')}
                                                </Text>
                                            </div> : null
                                        }*/}
                                        {(isEnough || projectTariffType !== pageParams.tariff.selected) ? null :
                                            <div>
                                                <Text type="secondary">{l('calc.pay.not_enough')}: </Text>
                                                <Text type="danger">
                                                    {roundPrice(tariffPrice - proj_balance)} {t('common.money.abbreviation')}
                                                </Text>
                                                {/*<Text type={isEnough ? null : "danger"}>{tariffPrice} {t('common.money.symbol')}</Text>*/}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <hr className="margin-top-md" style={{opacity: 0.5}}/>

                                {/* Кнопки оплаты */}
                                <Title className="margin-top-md" level={2}>{l('account.title')}</Title>

                                <div>
                                    {/*Баланс проекта*/}
                                    <div className="margin-top-sm flex flex-between">
                                        <div
                                            className="color-secondary font-size-md margin-right-ps margin-bottom-xs inline"
                                        >
                                            <span className="visible-xxl">
                                                {l('account.project.title')}

                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                        className="margin-right-xs"
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="hidden-xxl">
                                                {l('account.project.short_title')}
                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                        className="hidden-sm"
                                                    />
                                                </Tooltip>
                                            </span>

                                            <Title level={4} className="color-secondary"
                                                   style={{
                                                       fontWeight: 400,
                                                       marginTop: 0,
                                                       marginBottom: 0,
                                                       minWidth: 85
                                                   }}>
                                                {numberForHuman(roundPrice(proj_balance))} {t('common.money.abbreviation')}
                                            </Title>
                                        </div>

                                        <div className="inline">
                                            <Button
                                                type="primary"
                                                loading={order.loading.balance}
                                                //disabled={pageParams.amount.order < 100}
                                                icon={<ShoppingOutlined/>}
                                                onClick={showOrderModal}
                                            >{t('common.action.add_funds')}</Button>
                                        </div>
                                    </div>

                                    {/*Баланс вашего аккаунта*/}
                                    {balance > 0 ? <div className="margin-top-sm flex flex-between">
                                        <div
                                            className="color-secondary font-size-md margin-right-ps margin-bottom-xs inline">
                                            <span className="visible-xxl">
                                                {l('account.balance.title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                        className="margin-right-xs"
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="hidden-xxl">
                                                {l('account.balance.short_title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                        className="hidden-sm"
                                                    />
                                                </Tooltip>
                                            </span>

                                            <Title level={4} className="color-secondary"
                                                   style={{
                                                       fontWeight: 400,
                                                       marginTop: 0,
                                                       marginBottom: 0,
                                                       minWidth: 85
                                                   }}>
                                                {numberForHuman(roundPrice(balance))} {t('common.money.abbreviation')}
                                            </Title>
                                        </div>
                                        <div className="inline">
                                            <Button
                                                className="btn-bordered"
                                                type="primary"
                                                // loading={order.loading.project}
                                                loading={order.loading.balance}
                                                //disabled={true}
                                                disabled={!balance}
                                                icon={<ShoppingOutlined/>}
                                                onClick={() => setState('modal.project', true)}
                                            >{t('common.action.transfer')}</Button>
                                        </div>
                                    </div> : null}

                                    {/*Накоплено монет:*/}
                                    {/*<div className="margin-top-sm">
                                        <div className="color-secondary font-size-md float-left margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('calc.pay.coins')}
                                                <Tooltip title={l('account.coins.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('calc.pay.short_coins')}
                                                <Tooltip title={l('account.coins.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>
                                        <div className="float-right">
                                            <Button
                                                className="btn-bordered"
                                                type="default"
                                                icon={<QuestionCircleOutlined/>}
                                                onClick={() => setState('modal.promo', true)}
                                                title={t('common.action.get')}
                                                style={{width: 140}}
                                            >{t('common.action.get')}</Button>
                                        </div>
                                        <Title level={4} className="inline color-secondary"
                                               style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 60}}>
                                            {numberForHuman(coins)}
                                        </Title>
                                    </div>*/}

                                    {/* Add funds to project AI balance */}
                                    {/*projectTariffType !== 'free'*/}
                                    <div className="margin-top-sm flex flex-between">
                                        <div
                                            className="color-secondary font-size-md margin-right-ps margin-bottom-xs inline">
                                            <span className="visible-xxl">
                                                {l('account.ai.title')}

                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                        className="margin-right-xs"
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="hidden-xxl">
                                                {l('account.ai.short_title')}
                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                        className="hidden-sm"
                                                    />
                                                </Tooltip>
                                            </span>

                                            <Title
                                                level={4}
                                                className="color-secondary"
                                                style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}
                                            >
                                                {numberForHuman(roundPrice(ai_balance))} {t('common.money.abbreviation')}
                                            </Title>
                                        </div>

                                        <div className="inline">
                                            <Tooltip
                                                title={(balance + proj_balance) <= 0 ? l('calc.pay.not_enough_desc') : ''}
                                                placement="topRight"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        className="btn-bordered"
                                                        type="default"
                                                        loading={order.loading.ai}
                                                        icon={<PlusOutlined/>}
                                                        // href={`https://t.me/NeuroConverterMultiAI_bot?start=project-${project_id}`}
                                                        //disabled={true}
                                                        // disabled={(balance + proj_balance) <= 0}
                                                        onClick={() => setState('modal.ai', true)}
                                                    >{t('common.action.add_funds')}</Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div id="modals">
                                    {/* Add funds to project balance */}
                                    <InputModal
                                        initValue={''}
                                        isVisible={pageParams.modal.project}
                                        setVisible={(value) => {
                                            setState('modal.project', value)
                                        }}
                                        onModalOk={onProjectFormSubmit}
                                        title={l('account.project.modal.title')}
                                        placeholder={t('common.placeholder.amount')}
                                        loading={order.loading.project}
                                    />

                                    {/* Add funds to account balance */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.order}
                                        setVisible={(value) => {
                                            setState('modal.order', value)
                                        }}
                                        onOk={onOrderFormSubmit}
                                        title={l('account.balance.modal.title')}
                                        loadingOnOk={order.loading.balance}
                                        disabled={pageParams.amount.order === 0} // pageParams.amount.order === 0 && tariffPrice === 0
                                    >
                                        <div
                                            id="order-form"
                                            className="ant-form ant-form-vertical"
                                            // name="order-form"
                                            // layout="vertical"
                                            // form={orderForm}
                                            // // onValuesChange={onOrderFormChange}
                                            // onFinish={onOrderFormSubmit}
                                            // initialValues={{
                                            //     amount: 1000,
                                            //     phone: admin_phone,
                                            //     promo: pageParams.promo.code,
                                            //     subscribe: false,
                                            //     auto: false,
                                            // }}
                                        >
                                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                                <FormItemLabel
                                                    name="amount"
                                                    label={l('account.balance.modal.amount.label')}
                                                />
                                                <InputNumber
                                                    inputMode="numeric"
                                                    pattern="[0-9]*"
                                                    className="always-show-arrows"
                                                    min={0}
                                                    step={1000}
                                                    placeholder="1000"
                                                    onChange={(value) => setState('amount.order', value)}
                                                    value={pageParams.amount.order}
                                                    // defaultValue={tariffPrice ? tariffPrice : null}
                                                />
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.balance.modal.phone.desc')}>
                                                <FormItemLabel name="phone"
                                                               label={l('account.balance.modal.phone.label')}/>
                                                <Input
                                                    inputMode="numeric"
                                                    pattern="\+*[0-9]*"
                                                    placeholder={t('common.placeholder.text')}
                                                    onChange={(event, val) => {
                                                        // console.log('value', event, val)
                                                        setState('order.phone', event.target.value)
                                                    }}
                                                    value={pageParams.order.phone}
                                                    // defaultValue={admin_phone}
                                                />
                                            </FormItemWrapper>

                                            <FormItemWrapper className="" desc={l('account.balance.modal.promo.desc')}>
                                                <FormItemLabel name="promo"
                                                               label={l('account.balance.modal.promo.label')}/>
                                                <Input
                                                    showCount={true}
                                                    maxLength={30}
                                                    placeholder={t('common.placeholder.text')}
                                                    autoComplete='off'
                                                    onChange={(event) => {
                                                        // console.log('balance.modal.promo', event.target.value)
                                                        setState('promo.code', event.target.value)
                                                    }}
                                                    // defaultValue={pageParams.promo.code}
                                                    value={pageParams.promo.code}
                                                />
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.balance.modal.subscribe.desc')}>
                                                <LabelSwitch
                                                    label={l('account.balance.modal.subscribe.label')}
                                                    onChange={(value) => setState('order.subscribe', value)}
                                                    checked={pageParams.order.subscribe}
                                                />
                                            </FormItemWrapper>

                                            <FormItemWrapper
                                                desc={l('account.balance.modal.auto.desc')}
                                                className={pageParams.order.subscribe ? '' : 'hide'}
                                            >
                                                <LabelSwitch
                                                    label={l('account.balance.modal.auto.label')}
                                                    onChange={(value) => setState('order.auto', value)}
                                                    checked={pageParams.order.auto}
                                                />
                                            </FormItemWrapper>
                                        </div>
                                    </SimpleModal>

                                    {/* Get Coins */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.promo}
                                        setVisible={(value) => {
                                            setState('modal.promo', value)
                                        }}
                                        onOk={onPromoSave}
                                        actionLabel="save"
                                        title={l('account.promo.modal.title')}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: l('account.promo.modal.desc')}}/>
                                        <NoForm className="margin-top-sm">
                                            <FormItemWrapper className="" desc={l('account.promo.modal.field.desc')}>
                                                <FormItemLabel label={l('account.promo.modal.field.label')}/>
                                                <Input
                                                    showCount={true}
                                                    maxLength={30}
                                                    placeholder={t('common.placeholder.text')}
                                                    autoComplete='off'
                                                    defaultValue={pageParams.promo.name}
                                                    onBlur={(e) => {
                                                        setState('promo.name', e.target.value)
                                                    }}
                                                    onChange={(value) => {
                                                        setState('promo.name', value)
                                                    }}
                                                />
                                            </FormItemWrapper>
                                            <div className="float-right">{pageParams.promo.bonus}%</div>
                                            <Text>{l('account.promo.modal.slider.label')}</Text>
                                            <Slider
                                                className="primary"
                                                min={0}
                                                max={20}
                                                step={1}
                                                onChange={(value) => setState('promo.bonus', value)}
                                                value={pageParams.promo.bonus}
                                                defaultValue={pageParams.promo.bonus}
                                            />
                                            <div className="margin-top-sm">
                                                <div dangerouslySetInnerHTML={
                                                    {
                                                        __html: t('project.' + section + '.account.promo.modal.slider.desc', {
                                                            postProcess: 'sprintf',
                                                            sprintf: [
                                                                numberForHuman((20 - pageParams.promo.bonus) / 100 * 1000 * 10)
                                                            ]
                                                        })
                                                    }
                                                }/>
                                            </div>
                                        </NoForm>
                                    </SimpleModal>

                                    {/* Add funds to project AI balance */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.ai}
                                        setVisible={(value) => {
                                            setState('modal.ai', value)
                                        }}
                                        onOk={onAiOk}
                                        title={l('account.ai.modal.title')}
                                        loadingOnOk={order.loading.ai}
                                        // disabled={pageParams.ai.amount <= 0 || (
                                        //     pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > proj_balance ||
                                        //     !pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > balance
                                        // )}
                                    >
                                        <Form
                                            id="ai-balance-form"
                                            name="ai-balance-form"
                                            layout="vertical"
                                            form={aiForm}
                                            onValuesChange={onAiFormChange}
                                            onFinish={onAiFormSubmit}
                                        >
                                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                                <Form.Item
                                                    name="amount"
                                                    label={l('account.balance.modal.amount.label')}
                                                >
                                                    <InputNumber
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        className="always-show-arrows"
                                                        min={0}
                                                        step={1000}
                                                        placeholder="1000"
                                                        // onChange={(value) => setState('amount.order', value)}
                                                        // defaultValue={tariffPrice ? tariffPrice : null}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            {/*<FormItemWrapper desc={l('account.ai.modal.from_project.desc')}>
                                                <Form.Item name="from_project">
                                                    <LabelSwitch
                                                        label={l('account.ai.modal.from_project.label')}
                                                        onChange={(value) => setState('ai.from_project', value)}
                                                        checked={pageParams.ai.from_project}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>*/}
                                        </Form>

                                        {/*{pageParams.ai.amount > 0 && (
                                            pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > proj_balance ||
                                            !pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > balance
                                        ) ? <p
                                            className="color-danger"
                                        >
                                            {l('account.ai.modal.not_enough')}
                                        </p> : null}*/}

                                    </SimpleModal>
                                </div>
                            </ContentCard>
                        </Col>
                    </Row>

                </Content>
                <div className="margin-top-pl"/>
            </Layout>
        </AppWrapper>
    )
}

export default Overview